@import url("https://fonts.cdnfonts.com/css/poppins");

* {
  font-family: "Poppins", sans-serif;
}

p {
  line-height: 2;
}
h1,
h2,
h3,
h4,
h5 {
  line-height: normal;
}
.bg-green-01 {
  background-color: #204069 !important;
  color: aliceblue !important;
}
.bg-green-02 {
  background-color: #153a29 !important;
}
.hero-section {
  background-size: cover;
  background-position: contain;
  background-image: url("./assets/images/hero-section-img.png");
  height: 100vh;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.get-involved-section {
  background-image: url("./assets/images/join-section-img.png");
  background-size: cover;
  background-position: center;
}

.custom-bg {
  background: rgb(26, 74, 52);
  background: linear-gradient(
    188deg,
    rgba(26, 74, 52, 1) 11%,
    rgba(17, 52, 96, 1) 66%
  );
}

.t-text::first-letter {
  text-transform: uppercase;
}

.active-nav {
  background-color: red;
}

a {
  color: #153a29 !important;
}
